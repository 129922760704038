import React, { Component } from 'react';
import { styled } from "@material-ui/core/styles";
import Slider from 'react-slick';

import {
  Grid,
  Box,
  Card,
  Typography, CardMedia, Link
} from "@material-ui/core";

interface carouselList {
  id: string;
  type: string;
  attributes: {
    web_image: string;
    id: number;
    title: string;
    subtitle: string;
    description: string;
    link: string;
    rank: number;
    created_at: string;
    updated_at: string;
    image: string;
  };
}[];

type MyProps = { data: carouselList[] };
type MyState = { onChangeValue?: (value: boolean) => void; currentSlide: number; };

export default class CustomCarousel extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentSlide: 0
    }
  }


  render() {
    const { data } = this.props
    const settings = {
      centerMode: true,
      centerPadding: "0px",
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      focusOnSelect: true,
      beforeChange: (oldIndex: number, newIndex: number) => {
        this.setState({
          currentSlide: newIndex
        })
      },
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]

    };

    return (
      <>
        <MainConatinerGrid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='slider-container' style={{ width: '95% ' }}>
            <Slider {...settings}>
              {data && data.map((item: carouselList, index: number) => (
                <Grid key={index} className={`item ${this.state.currentSlide === index ? "slide activeSlide" : "slide"}`}>
                  <Card style={{ margin: '10px', boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px" }}>
                    <Box style={{ position: 'relative' }} key={index} >
                      <CardMedia
                        component={"img"}
                        image={item?.attributes?.web_image}
                        alt={`Slide ${index + 1}`}
                        style={{ position: 'relative', display: 'block', margin: 'auto' }}
                      />
                    </Box>
                    {this.state.currentSlide === index &&
                      <Box style={{ padding: '20px', textAlign: 'center' }}>
                        <Typography className='carouselText2' >{item?.attributes?.title}</Typography>
                        <Typography className='carouselText3' >{item?.attributes?.subtitle}</Typography>
                        <Link href={item?.attributes?.link} underline="always" target='_blank' className='carouselText' >{'View collection & Shop'}</Link >
                      </Box>}
                  </Card>
                </Grid>
              ))}

            </Slider>
          </div>
        </MainConatinerGrid>

      </>
    );
  }
}
import { UploadBtnBox } from "../../blocks/catalogue/src/CatalogueWeb.web";
import { CustomImgFont, UploadBtn, UploadText, webStyle } from "../../blocks/catalogue/src/EditCatalougeWeb.web";
import { deleteBttn ,upload} from "../../blocks/catalogue/src/assets";
import {  Button } from "@material-ui/core";
import i18n from "./i18next/i18n";

export const UploadBtnPart = (
    imgName: string,
    gridEditData: ImageData[],
    selectedImg: string,
    activeGrid: string,
    color: string,
    handleGridImage: (viewName: string, e: React.ChangeEvent<HTMLInputElement>) => void,
    handleActiveGrid: (name: string) => void,
    handleDeleteImage: (color: string, viewName: string) => void ,
    transAddProductCatalogue:any,
    fileInputRef:any
  ) => {
    if (!gridEditData) {
      return null;
    }
  
    return (
      <div style={{ margin: "22px" }} data-test-id="upload-edit-btn" key={"activeee" + activeGrid}>
        <h1 style={webStyle.titleImg} data-test-id="upload-edit-btn-title">{imgName}</h1>
  
        {selectedImg === '' ? (
          <>
          <UploadBtnBox data-test-id="upload-edit-btn-box">
            <Button
              component="label"
              style={{ paddingLeft: 50, display: "grid", textAlign: 'center',  boxShadow: 'none', width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }}
              variant="contained"
            >
              <img src={upload}
               data-test-id="hgjhgjhjh"

                alt="upload" 
                onError={(e)=>{
                  const target = e.target as HTMLImageElement; 
                  target.src = 'https://via.placeholder.com/150'; 
              
                }}
               />
              <UploadBtn data-test-id="upload-edit-btn-title-txt">
                {transAddProductCatalogue("Upload Image")}
              </UploadBtn>
              <UploadText data-test-id="upload-edit-btn-des">{transAddProductCatalogue("Only png or Jpg files are accepted")}</UploadText>
              <input
               ref={(input) => (fileInputRef = input)}
                disabled={activeGrid === ''}
                type="file"
                onChange={(e) => handleGridImage(activeGrid, e)} 
                hidden
                data-test-id="upload-image-test"
                accept=".png, .jpg, .jpeg"
              />
            </Button>
            </UploadBtnBox>
            <button
              style={webStyle.uploadBtn}
              data-test-id="genderSe11"
              onClick={() => fileInputRef?.click()} 
            >
              {transAddProductCatalogue("Upload Photo")}
            </button>
          </>
        ) : (
          <div style={{display: 'inline-block', position: 'relative',  }}>
            <img src={selectedImg} alt='testttt' style={{ objectFit: "contain", boxShadow: 'none', textAlign: 'center', display: "grid",  width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }} />
            <button 
              style={{
                height: '36px',
                bottom: '10px',
                right: '17px',
                backgroundColor: 'white',
                cursor: 'pointer',
                color: 'white',
                border: 'none',         
                borderRadius: '50%',
                position: 'absolute',
                width: '36px',
              }} 
              onClick={() => handleDeleteImage(color, activeGrid)}
            >
                  <img 
          src={deleteBttn}
          alt="Delete"
          style={{
          height: '30px',
          width: '30px',
        }}
      />
            </button>
          </div>
        )}
  
        <div style={{ display: "flex", marginTop: 18, gap: 22, flexDirection: i18n.dir()==="ltr" ? "row" : "row-reverse", justifyContent: i18n.dir()==="ltr" ? "flex-start" : "flex-end" }}>
          {gridEditData.map((item:any, index:number) => (
            <div 
              style={{ ...webStyle.boxesImg, border: activeGrid === item.name ? '2px solid #375280' : "" }} 
              key={`${item.name + index}`}
              onClick={() => handleActiveGrid(item.name)}
            >
              <img style={webStyle.allThreeimg}
              data-test-id="jhjhadsfdfsd"
              src={item.imgUrlLink} alt={transAddProductCatalogue(item.name)} 
               onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = 'https://via.placeholder.com/150'; 
              }}/>
              <CustomImgFont>{transAddProductCatalogue(item.name)}</CustomImgFont>
            </div>
          ))}
        </div>
      </div>
    );
  }

  export const AddUploadBtnPart = (
    imgName: string,
    griddata: ImageData[],
    selectedImg: string,
    activeGrid: string,
    color: string,
    handleGridImage: (color: string, viewName: string, e: React.ChangeEvent<HTMLInputElement>) => void,
    handleActiveGrid: (color: string, name: string) => void,
    handleDeleteImage: (color: string, viewName: string) => void ,
    transAddProductCatalogue:any,
    fileInputRef:any,
    imageError:any,
  ) => {
    if (!griddata) {
      return null;
    }
  
    return (
      <div style={{ margin: "22px" }} key={"activeee" + activeGrid}>
        <h1 style={webStyle.titleImg}>{imgName}</h1>
  
        {selectedImg === '' ? (
          <>
         <UploadBtnBox>
<Button
  component="label"
  style={{
    paddingLeft: 50,
    textAlign: 'center',
    display: 'grid',
    boxShadow: 'none',
    width: '535px',
    height: '230px',
    backgroundColor: '#F3F3F3',
    borderRadius: '2px',
  }}
  variant="contained"
>
  <img
    src={upload}
    alt="upload"
    data-test-id="uploadImg"
    onError={(e) => {
      const target = e.target as HTMLImageElement; 
      target.src = 'https://via.placeholder.com/150';
    }}
  />
  <UploadBtn>{transAddProductCatalogue('Upload Image')}</UploadBtn>
  <UploadText>{transAddProductCatalogue('Only png or Jpg files are accepted')}</UploadText>
  <input
    ref={(input) => (fileInputRef = input)} 
    disabled={activeGrid === ''}
    type="file"
    hidden
    data-test-id="upload-image-test"
    onChange={(e) => handleGridImage(color, activeGrid, e)}
    accept=".png, .jpg, .jpeg"
  />
</Button>
</UploadBtnBox>
<button
style={webStyle.uploadBtn}
data-test-id="genderSe11"
onClick={() => fileInputRef?.click()} 
>
{transAddProductCatalogue('Upload Photo')}
</button>

            {imageError && <p style={{ color: 'red' }}>{transAddProductCatalogue("Please select an image for each variant.")}</p>}

          </>
        ) : (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <img src={selectedImg} alt='testttt' style={{ objectFit: "contain", textAlign: 'center', display: "grid", boxShadow: 'none', width: '535px', height: '230px', backgroundColor: '#F3F3F3', borderRadius: '2px' }} />
            <button 
              style={{
                position: 'absolute',
                bottom: '10px',
                right: '17px',
                backgroundColor: 'white',
                color: 'white',
                border: 'none',         
                borderRadius: '50%',
                width: '36px',
                height: '36px',
                cursor: 'pointer'
              }} 
              onClick={() => handleDeleteImage(color, activeGrid)}
            >
                  <img 
          src={deleteBttn}
          alt="Delete"
          style={{
          width: '30px',
          height: '30px'
        }}
      />
            </button>
          </div>
        )}
  
        <div style={{ display: "flex", gap: 22, marginTop: 18, flexDirection: i18n.dir()==="ltr" ? "row" : "row-reverse", justifyContent: i18n.dir()==="ltr" ? "flex-start" : "flex-end" }}>
          {griddata.map((item:any, index:number) => (
            <div 
              style={{ ...webStyle.boxesImg, border: activeGrid === item.name ? '2px solid #375280' : "" }} 
              key={`${item.name + index}`}
              onClick={() => handleActiveGrid(color,item.name)}
            >
              <img style={webStyle.allThreeimg} src={item.imgUrlLink} alt={transAddProductCatalogue(item.name)}
              data-test-id='uploadsecondimg'
                onError={(e) => {
                  const target = e.target as HTMLImageElement; 
                  target.src = 'https://via.placeholder.com/150'; 
                }}
              
              />
              <CustomImgFont>{transAddProductCatalogue(item.name)}</CustomImgFont>
            </div>
          ))}
        </div>
      </div>
    );
  }
const MainConatinerGrid = styled(Grid)({
  "& .activeSlide": {
    transform: 'scale(1.4) !important',
    opacity: '1 !important',
  },
  "& .slide": {
    transform: 'scale(1.0) !important',
    opacity: '0.9 !important',
    transition: 'transform 300ms !important',
    "@media(max-width:1440px)": {
     display: "flex !important",
     justifyContent: "center !important",
    },
  },
  "& .slide img": {
    maxWidth: '100%',
    height: '311px !important',
    objectFit: "fill" as "fill"
  },
  "& .slide .MuiCard-root": {
    position: 'relative',
    height: '400px !important',
    transition: '0.3s !important',
    margin: '0 20px !important', // Spacing between cards for non-active slides
  },
  "& .activeSlide .MuiCard-root": {
    "@media(max-width:1440px)": {
      right: '0px !important',
    },
    "@media (min-width: 1441px) and (max-width: 1900px)": {
      right: '7% !important',
      width: "100% !important"
    },
    height: '480px !important',
    transition: '0.3s !important',
    margin: '0 40px !important', // Increase spacing between cards for active slide
    width: '700px !important',
    position: 'relative',
    right: '89px !important'
  },
  "& .slick-list": {
    padding: '20px 0 !important',
    height: '500px !important'
  },
  "& .slick-prev": {
    left: '100px !important',
  },
  "& .slick-next": {
    right: '100px !important',
  },
  "& .slick-slide": {
    outline: 'none !important'
  },
  /* Apply transform to slides other than the current slide */
  "& .slick-slide:not(.slick-center) .MuiCard-root": {
    transform: 'scale(0.8) !important'
  },
  "& .slick-dots li button::before": {
    fontSize: '21px !important',
    color: '#375280 !important',
  },
  "& .slick-prev::before": {
    color: 'red !important'
  },
  " &.slick-next::before": {
    color: 'red !important'
  },
  "& .carouselText": {
    color: '#375280',
    fontSize: '20px',
    fontWeight: 700,
    paddingTop: '5px',
    fontFamily: "Lato"
  },
  "& .carouselText2": {
    color: '#375280',
    fontSize: '20px',
    fontWeight: 400,
    textAlign: "center" as "center",
    fontFamily: "Lato"
  },
  "& .carouselText3": {
    color: '#375280',
    fontSize: '28px',
    fontWeight: 800,
    textAlign: "center" as "center",
    paddingTop: '5px',
    paddingBottom: '5px',
    fontFamily: "Lato"

  },
});




