import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CreateSellerStoreController, {
  averageDeliveryModes,
  configJSON,
  FormError,
  FormErrorTouched,
  Props,
  step2Schema,
  step3Schema,
} from "./CreateSellerStoreController";
import {
  Grid,
  styled,
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  Dialog,
  CircularProgress as LoadingCircularProgress,
  Backdrop as LoadingBackdrop,
  Tooltip
} from "@material-ui/core";
import Footer from "../../../components/src/Footer";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import CustomizedSteppers from "../../../components/src/Stepper";
import {
  backBtnIcon,
  editUploadIcon,
  endLocationIcon,
  selectEndIcon,
  step1,
  step3,
  image_background,
  storeSuccessIcon,
  uploadIcon,
} from "./assets";
import { Formik } from "formik";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReusableSnackbar from "../../../components/src/AlertContainer";
import { GoogleMap, Marker } from "@react-google-maps/api";
import i18n from "../../../components/src/i18next/i18n";
import CountrySelect from "../../../components/src/CountryDropDown.web"
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class CreateSellerStore extends CreateSellerStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  backDropCreateStore = () => (
    <LoadingBackdrop style={{ zIndex: 1000 }} open={this.state.backDropLoader} invisible={false}>
      <LoadingCircularProgress style={{ color: "#375280" }} />
    </LoadingBackdrop>
  )
  handleUseCurrentLocationStore = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
  
          this.setState({
            currentLocationStore: currentLatLng,
            selectedLocationStore: currentLatLng, 
            mapVisible: true, 
              latitude: currentLatLng.lat,
              longitude: currentLatLng.lng,
           
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    } 
  };
  handleUseCurrentLocationStoreArabic = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
  
          this.setState({
            currentLocationStoreAr: currentLatLng,
            selectedLocationStoreAr: currentLatLng, 
            arabicMapVisible: true, 
              latitude: currentLatLng.lat,
              longitude: currentLatLng.lng,
           
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    } 
  };
 
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({
      step2: {
        ...this.state.step2,
        [name]: value,
      },
    });
  };
  renderGoogleMap() {
    return (
      this.state.mapsLoadedStore &&
      this.state.mapVisible &&
      this.state.currentLocationStore && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocationStore || { lat: -34.397, lng: 150.644 }}
        >
          {this.state.selectedLocationStore && (
            <Marker position={this.state.selectedLocationStore} />
          )}
        </GoogleMap>
      )
    );
  }
  renderGoogleMapArabic() {
    return (
      this.state.mapsLoadedStore &&
      this.state.arabicMapVisible &&
      this.state.currentLocationStoreAr && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocationStoreAr || { lat: -34.397, lng: 150.644 }}
        >
          {this.state.selectedLocationStoreAr && (
            <Marker position={this.state.selectedLocationStoreAr} />
          )}
        </GoogleMap>
      )
    );
  }
  renderStepper1 = () => (
    <form data-test-id="formSubmit" onSubmit={this.handleSubmit1} dir={i18n.dir()}>
      {!this.state.step1.previewUrl ? (
        <Button variant="contained" component="label" className="upload-btn">
          <img src={uploadIcon} alt="upload" />
          <Typography className="upload-title">
            {this.tranCreateStore("Upload Store Profile Image")}
          </Typography>
          <input
            type="file"
            hidden
            data-test-id="upload-image-test"
            onChange={this.handleFileChange}
            accept=".png, .jpg, .jpeg"
          />
        </Button>
      ) : (
        <Box
          className="image-preview-box"
          style={{ position: "relative", marginTop: "16px" }}
        >
          <img
            src={this.state.step1.previewUrl}
            alt="Uploaded Profile"
            className="image-preview"
            style={{
              width: "100%",
              height: 240,
              objectFit: "cover",
            }}
          />
          <Button
            variant="contained"
            component="label"
            className="edit-btn"
            style={{
              position: "absolute",
              top: "6px",
              right: "0px",
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <img src={editUploadIcon} alt="edit" />
            <input
              type="file"
              data-test-id="re-upload-image-test"
              hidden
              onChange={this.handleFileChange}
              accept=".png, .jpg, .jpeg"
            />
          </Button>
        </Box>
      )}
      <FormHelperText
        data-test-id="upload-img-error"
        style={{ color: "#f44336",display:"flex" }}
        dir={i18n.dir()}
      >
        {this.state.errorsMessageStep1.errorStoreUpload
          ? this.state.errorsMessageStep1.errorStoreUpload
          : ""}
      </FormHelperText>
      <Typography className={"inputHeading"}>{this.tranCreateStore("Store Name")} *</Typography>
      <TextField
        data-test-id="store-name"
        fullWidth
        variant="outlined"
        className={
          this.state.errorsMessageStep1.storeNameError
            ? "inputFeild2Error2"
            : "inputFeild2"
        }
        placeholder={this.tranCreateStore("Enter Store Name")}
        value={this.state.step1.storeName}
        onChange={this.handleStoreName}
        inputProps={{ maxLength: 120 }}
        error={this.state.errorsMessageStep1.storeNameError}
        dir={i18n.dir()}
        helperText={
          this.state.errorsMessageStep1.errorStoreName
            ? this.state.errorsMessageStep1.errorStoreName
            : ""
        }
      />
       <Typography className={"inputHeading"}>{this.tranCreateStore("Store Name")} {this.tranCreateStore("Arabic")} *</Typography>
      <TextField
        data-test-id="store-nameArabic"
        fullWidth
        variant="outlined"
        className={
          this.state.errorsMessageStep1.storeNameErrorArabic
            ? "inputFeild2Error2"
            : "inputFeild2"
        }
        placeholder={this.tranCreateStore("Enter Store Name")}
        value={this.state.step1.storeNameArabic}
        onChange={this.handleStoreNameArabic}
        inputProps={{ maxLength: 120 }}
        error={this.state.errorsMessageStep1.storeNameErrorArabic}
        dir={i18n.dir()}
        helperText={
          this.state.errorsMessageStep1.errorStoreNameArabic
            ? this.state.errorsMessageStep1.errorStoreNameArabic
            : ""
        }
      />
      
      <Typography className={"inputHeading"}>
      {this.tranCreateStore("Store Description")} *
      </Typography>
      <TextFieldComponent
      errorCheck={i18n.dir()}
        data-test-id="store-description"
        fullWidth
        variant="outlined"
        className={
          this.state.errorsMessageStep1.storeDescriptionError
            ? "inputFeild2Error2"
            : "inputFeild2"
        }
        placeholder={this.tranCreateStore("Enter Store Description")}
        value={this.state.step1.storeDescription}
        multiline
        inputProps={{ maxLength: 420 }}
        rows={3}
        onChange={this.handleStoreDescription}
        error={this.state.errorsMessageStep1.storeDescriptionError}
        dir={i18n.dir()}
        helperText={
          this.state.errorsMessageStep1.errorStoreDescription
            ? this.state.errorsMessageStep1.errorStoreDescription
            : ""
        }
      />
       <Typography className={"inputHeading"}>
      {this.tranCreateStore("Store Description")} {this.tranCreateStore("Arabic")} *
      </Typography>
      <TextField
        data-test-id="store-description-arabic"
        fullWidth
        variant="outlined"
        className={
          this.state.errorsMessageStep1.storeDescriptionErrorArabic
            ? "inputFeild2Error2"
            : "inputFeild2"
        }
        placeholder={this.tranCreateStore("Enter Store Description")}
        value={this.state.step1.storeDescriptionArabic}
        multiline
        inputProps={{ maxLength: 420 }}
        rows={3}
        name="storeDescriptionArabic"
        onChange={this.handleStoreDescriptionArabic}
        error={this.state.errorsMessageStep1.storeDescriptionErrorArabic}
        dir={i18n.dir()}
        helperText={
          this.state.errorsMessageStep1.errorStoreDescriptionArabic
            ? this.state.errorsMessageStep1.errorStoreDescriptionArabic
            : ""
        }
      />
      <Box className="btn-container">
        <Button variant="outlined" onClick={() => this.handleBack()} className={"back-btn"} disableElevation data-test-id="form-1-back-btn">
          {this.tranCreateStore("Back")}
        </Button>
        <Button
          variant="contained"
          className={"next-btn"}
          disableElevation
          type="submit"
        >
          {this.tranCreateStore("Next")}
        </Button>
      </Box>
    </form>
  );

  handleIconCSS = () => {
    return i18n.dir()==='ltr' ? {right: this.state.isDeliveryOpen ? 10 : 0, paddingRight: 10,} : {left: this.state.isDeliveryOpen ? 10 : 0, paddingLeft: 10}
  }

  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{ position: "absolute",
        zIndex: 1,
        transform: this.state.isDeliveryOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease",
        pointerEvents: "none",
        ...this.handleIconCSS() }}
      data-test-id="select-drop-down-img"
    />
  );

  renderStepper2 = () => (
    <Formik
      initialValues={this.state.step2}
      validationSchema={step2Schema}
      enableReinitialize
      onSubmit={this.handleSubmit2}
      data-test-id="stepper-2-form-data"
      dir={i18n.dir()}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
      }) => {

        const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(e);
          this.setState((prevState)=> ({
            ...prevState,
            invalidContact: ""
          }))
        };
        return (
          <form onSubmit={handleSubmit} dir={i18n.dir()}>
            <Typography className={"inputHeading"}>
              {this.tranCreateStore("Address")} *
            </Typography>
            <TextFieldComponent
              {...getFieldProps("address")}
              fullWidth
              id="autocomplete5"
              errorCheck={i18n.dir()}
              onClick={this.handleUseCurrentLocationStore}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {<img src={endLocationIcon} alt="location" />}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.address)}`}
              type="text"
              className="inputFeild2"
              dir={i18n.dir()}
              error={
                this.getErrorAndHelperText(
                  "address",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              value={this.state.step2.address}
onChange={this.handleInputChange}
              helperText={
                this.getErrorAndHelperText(
                  "address",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              name="address"

              inputProps={{ maxLength: 120, id: "autocomplete5" }}
              data-test-id="create-store-address"
            />
            <div style={{ position: "relative" }}>
      {this.renderGoogleMap()}
    </div>
   <Typography className={"inputHeading"}>
              {this.tranCreateStore("Address")} {this.tranCreateStore("Arabic")} *
            </Typography>
            <TextField
              {...getFieldProps("addressArabic")}
              fullWidth
              id="autocomplete6"
              onClick={this.handleUseCurrentLocationStoreArabic}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {<img src={endLocationIcon} alt="location" />}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.address)}`}
              type="text"
              className="inputFeild2"
              dir={i18n.dir()}
              error={
                this.getErrorAndHelperText(
                  "addressArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              value={this.state.step2.addressArabic}
onChange={this.handleInputChange}
              helperText={
                this.getErrorAndHelperText(
                  "addressArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              name="addressArabic"

              inputProps={{ maxLength: 120, id: "autocomplete6" }}
              data-test-id="create-store-address"
            /> 
            <div style={{ position: "relative" }}>
      {this.renderGoogleMapArabic()}
    </div>
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.area)} *
            </Typography>
            <TextFieldComponent
              {...getFieldProps("area")}
              fullWidth
              id="area"
              errorCheck={i18n.dir()}
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.area)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "area",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "area",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-area"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.area)} {this.tranCreateStore("Arabic")} *
            </Typography>
            <TextField
              {...getFieldProps("areaArabic")}
              fullWidth
              id="areaArabic"
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.area)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "areaArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "areaArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-areaArabic"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.block)} *
            </Typography>
            <TextFieldComponent
              {...getFieldProps("block")}
              fullWidth
              id="block"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.block)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "block",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "block",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-block"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.block)} {this.tranCreateStore("Arabic")} *
            </Typography>
            <TextField
              {...getFieldProps("blockArabic")}
              fullWidth
              id="blockArabic"
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.block)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "blockArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "blockArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-block"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.mallName)} ({this.tranCreateStore(configJSON.optionalString)})
            </Typography>
            <TextFieldComponent
              {...getFieldProps("mallName")}
              fullWidth
              id="mallName"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.mallName)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "mallName",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "mallName",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-mall-name"
            />

<Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.mallName)} {this.tranCreateStore("Arabic")} ({this.tranCreateStore(configJSON.optionalString)})
            </Typography>
            <TextFieldComponent
              {...getFieldProps("mallNameArabic")}
              fullWidth
              id="mallNameArabic"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.mallNameArabic)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "mallNameArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "mallNameArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-mall-nameArabic"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.floor)} ({this.tranCreateStore(configJSON.optionalString)})
            </Typography>
            <TextFieldComponent
              {...getFieldProps("floor")}
              fullWidth
              id="floor"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.floor)}`}
              type="text"
              className="inputFeild2"
              inputProps={{ maxLength: 50 }}
              error={
                this.getErrorAndHelperText(
                  "floor",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "floor",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-floor"
            />

<Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.floor)} {this.tranCreateStore("Arabic")} ({this.tranCreateStore(configJSON.optionalString)})
            </Typography>
            <TextFieldComponent
              {...getFieldProps("floorArabic")}
              fullWidth
              id="floorArabic"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.floor)}`}
              type="text"
              className="inputFeild2"
              inputProps={{ maxLength: 50 }}
              error={
                this.getErrorAndHelperText(
                  "floorArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "floorArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-floorArabic"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.unitNumber)} ({this.tranCreateStore(configJSON.optionalString)})
            </Typography>
            <TextFieldComponent
              {...getFieldProps("unitNumber")}
              fullWidth
              id="unitNumber"
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.unitNumber)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "unitNumber",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "unitNumber",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-unit-number"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.city)} *
            </Typography>
            <TextFieldComponent
              {...getFieldProps("city")}
              fullWidth
              id="city"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.city)}`}
              type="text"
              className="inputFeild2"
              inputProps={{ maxLength: 50 }}
              error={
                this.getErrorAndHelperText(
                  "city",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "city",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-city"
            />

          <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.city)} {this.tranCreateStore("Arabic")} *
            </Typography>
            <TextField
              {...getFieldProps("cityArabic")}
              fullWidth
              id="cityArabic"
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.city)}`}
              type="text"
              className="inputFeild2"
              inputProps={{ maxLength: 50 }}
              error={
                this.getErrorAndHelperText(
                  "cityArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "cityArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-city"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.zipCode)}*
            </Typography>
            <TextFieldComponent
              {...getFieldProps("zipCode")}
              fullWidth
              id="zipCode"
              variant="outlined"
              errorCheck={i18n.dir()}
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.zipCode)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "zipCode",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "zipCode",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              inputProps={{ maxLength: 6 }}
              data-test-id="create-store-zip-code"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.instruction)} *
            </Typography>
            <TextFieldComponent
              {...getFieldProps("instruction")}
              fullWidth
              id="zipCode"
              errorCheck={i18n.dir()}
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.instructionForDrivePlace)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "instruction",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 120 }}
              helperText={
                this.getErrorAndHelperText(
                  "instruction",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-instruction"
            />

        <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.instruction)} {this.tranCreateStore("Arabic")} *
            </Typography>
            <TextField
              {...getFieldProps("instructionArabic")}
              fullWidth
              id="instructionArabic"
              variant="outlined"
              placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.instructionForDrivePlace)}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "instructionArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 120 }}
              helperText={
                this.getErrorAndHelperText(
                  "instructionArabic",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-instruction"
            />
            <Typography className={"inputHeading"}>
              {this.tranCreateStore(configJSON.phoneNumber)} *
            </Typography>
            <Box style={{ display: "flex", gap: 14, marginTop: 10 }}>
              <Box style={{ position: "relative",
    cursor: "pointer",
    width: "200px"}}>
                <CountrySelect            
                  placeholder={`${this.tranCreateStore(configJSON.selectString)} ${this.tranCreateStore(configJSON.phoneNumber)}`}
                  value={values.phoneCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  countryCode={this.state.countryCode}  
                  id="phoneCode"
                  name="phoneCode"                                    />
              </Box>
              <TextFieldComponent
                {...getFieldProps("phoneNumber")}
                fullWidth
                errorCheck={i18n.dir()}
                id="phoneNumber"
                variant="outlined"
                placeholder={`${this.tranCreateStore(configJSON.enterString)} ${this.tranCreateStore(configJSON.phoneNumber)}`}
                type="text"
                className="inputFeild2"
                onChange={handlePhoneChange}
                error={
                  this.getErrorAndHelperText(
                    "phoneNumber",
                    errors as FormError,
                    touched as FormErrorTouched
                  ).isError || this.state.invalidContact !== ""
                }
                helperText={
                  this.getErrorAndHelperText(
                    "phoneNumber",
                    errors as FormError,
                    touched as FormErrorTouched
                  ).helperText || this.state.invalidContact
                }
                data-test-id="create-store-phone-number"
              />
            </Box>
            <Box className="btn-container">
              <Button
                variant="outlined"
                className={"back-btn"}
                onClick={() => this.handleBack()}
                disableElevation
                data-test-id="back-icon-test-id"
              >
                {this.tranCreateStore(configJSON.back)}
              </Button>
              <Button
                variant="contained"
                className={"next-btn"}
                disableElevation
                type="submit"
              >
                {this.tranCreateStore(configJSON.next)}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );

  renderStepper3 = () => (
    <Formik
      initialValues={this.state.step3}
      validationSchema={step3Schema}
      enableReinitialize
      onSubmit={this.handleSubmit3}
      data-test-id="stepper-3-form-data"
      dir={i18n.dir()}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
      }) => {
        
        const handleDateChange = (value: Date | null, field: string) => {
          setFieldValue(field, value ? value.toISOString() : "");
        };
        return (
          <form onSubmit={handleSubmit} dir={i18n.dir()}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <React.Fragment key={"mon"}>
                  <Grid item xs={2} id="monday-title">
                  <Tooltip id="monday-title-tooltip" title={`${this.tranCreateStore(this.capitalizeFirstLetter("mon"))}`}><Step3Title id="monday-title-name">
                    {this.tranCreateStore(this.capitalizeFirstLetter("mon"))}*
                    </Step3Title></Tooltip>
                  </Grid>
                  <Grid item xs={5} id="monday-from-box">
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("monFrom")}
                        dir={i18n.dir()}
                        margin="normal"
                        variant="dialog"
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        value={this.valueStep3(values.monFrom)}
                        error={
                          this.getErrorAndHelperText(
                            "monFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        onChange={(time) => {handleDateChange(time, "monFrom")}}
                        helperText={
                          this.getErrorAndHelperText(
                            "monFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="mon-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("monTo")}
                        margin="normal"
                        placeholder={this.tranCreateStore((configJSON.placeholderTo))}
                        variant="dialog"
                        value={this.valueStep3(values.monTo)}
                        onChange={(time) => {handleDateChange(time, "monTo")}}
                        error={
                          this.getErrorAndHelperText(
                            "monTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "monTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="mon-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"tue"}>
                  <Grid item xs={2} id="tuesday-title">
                  <Tooltip id="tuesday-title-tooltip" title={`${this.tranCreateStore(this.capitalizeFirstLetter("tue"))}`}><Step3Title id="tuesday-title-name">
                    {this.tranCreateStore(this.capitalizeFirstLetter("tue"))}*
                    </Step3Title></Tooltip>
                  </Grid>
                  <Grid item xs={5} id="tuesday-from-box">
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("tueFrom")}
                        margin="normal"
                        variant="dialog"
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "tueFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "tueFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueStep3(values.tueFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "tueFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="tue-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("tueTo")}
                        margin="normal"
                        variant="dialog"
                        placeholder={this.tranCreateStore(configJSON.placeholderTo)}
                        value={this.valueStep3(values.tueTo)}
                        onChange={(time) => handleDateChange(time, "tueTo")}
                        error={
                          this.getErrorAndHelperText(
                            "tueTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "tueTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="tue-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"wed"}>
                  <Grid item xs={2} id="wednesday-title">
                  <Tooltip id="wednesday-title-tooltip" title={`${this.tranCreateStore(this.capitalizeFirstLetter("wed"))}`}>
                    <Step3Title id="wednesday-title-name">
                  {this.tranCreateStore(this.capitalizeFirstLetter("wed"))}*
                    </Step3Title></Tooltip>
                  </Grid>
                  <Grid item xs={5} id="wednesday-from-box">
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("wedFrom")}
                        margin="normal"
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        variant="dialog"
                        value={this.valueStep3(values.wedFrom)}
                        onChange={(time) => handleDateChange(time, "wedFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "wedFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "wedFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="wed-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("wedTo")}
                        variant="dialog"
                        placeholder={this.tranCreateStore(configJSON.placeholderTo)}
                        margin="normal"
                        value={this.valueStep3(values.wedTo)}
                        onChange={(time) => handleDateChange(time, "wedTo")}
                        helperText={
                          this.getErrorAndHelperText(
                            "wedTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        error={
                          this.getErrorAndHelperText(
                            "wedTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="wed-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"thu"}>
                  <Grid item xs={2} id="thursday-title">
                  <Tooltip id="thursday-title-tooltip" title={`${this.tranCreateStore(this.capitalizeFirstLetter("thu"))}`}><Step3Title id="thursday-title-name">
                  {this.tranCreateStore(this.capitalizeFirstLetter("thu"))}*
                    </Step3Title></Tooltip>
                  </Grid>
                  <Grid item xs={5} id="thursday-from-box">
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("thuFrom")}
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        variant="dialog"
                        value={this.valueStep3(values.thuFrom)}
                        margin="normal"
                        onChange={(time) => handleDateChange(time, "thuFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "thuFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "thuFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="thu-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("thuTo")}
                        placeholder={this.tranCreateStore(configJSON.placeholderTo)}
                        onChange={(time) => handleDateChange(time, "thuTo")}
                        variant="dialog"
                        value={this.valueStep3(values.thuTo)}
                        margin="normal"
                        error={
                          this.getErrorAndHelperText(
                            "thuTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "thuTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="thu-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"fri"}>
                  <Grid item id="friday-title" xs={2} >
                  <Tooltip title={`${this.tranCreateStore(this.capitalizeFirstLetter("fri"))}`} id="friday-title-tooltip">
                    <Step3Title id="friday-title-name">
                  {this.tranCreateStore(this.capitalizeFirstLetter("fri"))}*
                    </Step3Title></Tooltip>
                  </Grid>
                  <Grid item xs={5} id="friday-from-box">
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("friFrom")}
                        margin="normal"
                        error={
                          this.getErrorAndHelperText(
                            "friFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="fri-from-date-test"
                        variant="dialog"
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "friFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueStep3(values.friFrom)}
                        onChange={(time) => handleDateChange(time, "friFrom")}
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("friTo")}
                        margin="normal"
                        variant="dialog"
                        data-test-id="fri-to-date-test"
                        placeholder={this.tranCreateStore(configJSON.placeholderTo)}
                        value={this.valueStep3(values.friTo)}
                        onChange={(time) => handleDateChange(time, "friTo")}
                        error={
                          this.getErrorAndHelperText(
                            "friTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "friTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"sat"}>
                  <Grid item xs={2} id="saturday-title">
                  <Tooltip id="saturday-title-tooltip" title={`${this.tranCreateStore(this.capitalizeFirstLetter("sat"))}`}>
                    <Step3Title id="saturday-title-name">
                  {this.tranCreateStore(this.capitalizeFirstLetter("sat"))}*
                    </Step3Title>
                    </Tooltip>
                  </Grid>
                  <Grid id="saturday-from-box" item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("satFrom")}
                        margin="normal"
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "satFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "satFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueStep3(values.satFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "satFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="sat-from-date-test"
                        variant="dialog"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("satTo")}
                        data-test-id="sat-to-date-test"
                        helperText={
                          this.getErrorAndHelperText(
                            "satTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        margin="normal"
                        placeholder={this.tranCreateStore(configJSON.placeholderTo)}
                        error={
                          this.getErrorAndHelperText(
                            "satTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        variant="dialog"
                        onChange={(time) => handleDateChange(time, "satTo")}
                        value={this.valueStep3(values.satTo)}
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"sun"}>
                  <Grid item id="sunday-title" xs={2}>
                  <Tooltip title={`${this.tranCreateStore(this.capitalizeFirstLetter("sun"))}`} 
                    id="sunday-title-tooltip">
                      <Step3Title id="sunday-title-name">
                          {this.tranCreateStore(this.capitalizeFirstLetter("sun"))}*
                    </Step3Title>
                  </Tooltip>
                  </Grid>
                  <Grid item xs={5} id="sunday-from-box">
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("sunFrom")}
                        variant="dialog"
                        error={
                          this.getErrorAndHelperText(
                            "sunFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="sun-from-date-test"
                        placeholder={this.tranCreateStore(configJSON.placeholderFrom)}
                        onChange={(time) => handleDateChange(time, "sunFrom")}
                        margin="normal"
                        helperText={
                          this.getErrorAndHelperText(
                            "sunFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueStep3(values.sunFrom)}
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("sunTo")}
                        onChange={(time) => handleDateChange(time, "sunTo")}
                        margin="normal"
                        placeholder={this.tranCreateStore(configJSON.placeholderTo)}
                        data-test-id="sun-to-date-test"
                        error={
                          this.getErrorAndHelperText(
                            "sunTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueStep3(values.sunTo)}
                        helperText={
                          this.getErrorAndHelperText(
                            "sunTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        variant="dialog"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <Typography className={"inputHeading"}>
                  {this.tranCreateStore("Average time to ship order")}*
                </Typography>
                <FormControl className="finance-status" fullWidth error={touched.averageTime && Boolean(errors.averageTime)}>
                  <Select
                    id="averageTime"
                    name="averageTime"
                    className="inputFeild2"
                    variant="outlined"
                    fullWidth
                    placeholder={`${this.tranCreateStore(configJSON.selectString)} ${this.tranCreateStore("Average Delivery Time")}`}
                    value={values.averageTime}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    data-test-id="average-time-date-test"
                    style={{
                      marginTop: 5,
                      borderRadius: 2,
                      backgroundColor: "#F0F0F0",
                    }}
                    IconComponent={this.selectIconRender}
                    onOpen={this.handleDeliveryDropDown}
                    onClose={this.handleDeliveryDropDown}
                    displayEmpty
                    MenuProps={{
                      style: { minWidth: "180px", height: "320px" },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: i18n.dir() === "rtl" ? "right":"left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: i18n.dir() === "rtl" ? "right":"left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem disabled value="" dir={i18n.dir()}>
                      <span>{`${this.tranCreateStore(configJSON.selectString)} ${this.tranCreateStore("Average Delivery Time")}`}</span>
                    </MenuItem>
                    {averageDeliveryModes.map(({ label, value }) => (
                      <MenuItem
                      dir={i18n.dir()}
                        style={{
                          fontFamily: "Lato, sans-serif",
                          fontSize: 16,
                          color: "#375280",
                          display: "flex"
                        }}
                        key={value}
                        value={value}
                      >
                        <ListItemText style={{display: "flex"}} primary={this.tranCreateStore(label)} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.averageTime && errors.averageTime && (
                      <FormHelperText>{this.tranCreateStore(errors.averageTime)}</FormHelperText>
                    )}
                </FormControl>

                <Box className="btn-container">
                  <Button
                    variant="outlined"
                    className={"back-btn"}
                    onClick={() => this.handleBack()}
                    disableElevation
                    data-test-id="back-icon-test-id"
                  >
                    {this.tranCreateStore(configJSON.back)}
                  </Button>
                  <Button
                    variant="contained"
                    className={"next-btn"}
                    disableElevation
                    type="submit"
                  >
                    {this.tranCreateStore(configJSON.create)}
                  </Button>
                </Box>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      }}
    </Formik>
  );

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div ref={this.scrollContainerRef} >
        <SellerHeader navigation={this.props.navigation} isSellerForm />
        <MainGrid container dir={i18n.dir()}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              {this.state.activeStep === 0 && (
                <img className="sideImage" src={step1} alt="step-1" />
              )}
              {this.state.activeStep === 1 && (
                <img className="sideImage" src={image_background} alt="step-2" />
              )}
              {this.state.activeStep === 2 && (
                <img className="sideImage" src={step3} alt="step-3" />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={"formGrid"}
            
          >
            <Box className="title-container" >
              <img src={backBtnIcon} alt="backIcon" 
              style={{
                transform: i18n.dir() === "rtl" ? "rotate(180deg)" : "rotate(0)"
              }} 
              onClick={() => this.handleBack()}/>
              <Typography className="title">{this.tranCreateStore("Create A Store")}</Typography>
            </Box>
            <Box className="stepper-container" style={{paddingLeft: i18n.dir() === "rtl" ?"15px": 0}}>
              <CustomizedSteppers
              dir={i18n.dir()}
                activeStep={this.state.activeStep}
                steps={[`${this.tranCreateStore("Store Details")}`, `${this.tranCreateStore("Address")}`, `${this.tranCreateStore("Timings")}`]}
                stepCount={3}
              />
            </Box>
            <Box className="step-1-container" dir={i18n.dir()}>
              {this.state.activeStep === 0 && this.renderStepper1()}
              {this.state.activeStep === 1 && this.renderStepper2()}
              {this.state.activeStep === 2 && this.renderStepper3()}
            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
        </div>
        <DialogContainer data-test-id="create-store-success-dialog" open={this.state.isSuccess} onClose={()=>this.handleRedirect("MyStore")} dir={i18n.dir()}>
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">{this.tranCreateStore("Store successfully registered!")}</Typography>
          </Box>
        </DialogContainer>
        {this.backDropCreateStore()}
        <ReusableSnackbar
          open={this.state.alertBox.isAlert}
          message={this.state.alertBox.message}
          onClose={this.onAlertBoxClose}
          severity={this.state.alertBox.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
          dir={i18n.dir()}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const MainGrid = styled(Grid)({
  "& .imageGrid": {
    width: "100%",
    "& .sideImageBox": {
      width: "100%",
      height: "100%",
      "& .sideImage": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
  "& .formGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 100,
    flexDirection: "column",
    width: "100%",
    "@media(max-width:520px)": {
      padding: 10,
    },
    "& .title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      height: 48,
      width: "100%",
      "& .title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 800,
        fontSize: 24,
        lineHeight: 26,
        color: "#375280",
      },
    },
    "& .stepper-container": {
      width: "100%",
    },
    "& .step-1-container": {
      width: "100%",
      "& .upload-btn": {
        boxShadow: "none",
        backgroundColor: "#F8F8F8 !important",
        width: "100%",
        height: 240,
        "& .MuiButton-label": {
          display: "flex",
          flexDirection: "column",
          gap: 16,
        },
        "& .upload-title": {
          fontFamily: "Lato, sans-serif",
          fontSize: 18,
          fontWeight: 700,
          color: "#375280",
          textTransform: "capitalize",
        },
      },
      "& .inputHeading": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        paddingTop: 26,
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
      },
      "& .inputFeild2Error2": {
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#F0F0F0",
          borderRadius: "2px",
          border: "none",
        },
        "& .MuiFormHelperText-contained": {
          margin: "0 !important",
          display: "flex"
        },
        "& .MuiInputBase-input": {
          color: "#375280",
        },
      },
      "& .MuiFormControl-root":{
        "& .MuiInputBase-root.MuiOutlinedInput-root.inputFeild2.Mui-error":{
          border: "1px solid #f44336",
        }
      },
      "& .inputFeild2": {
        "& .MuiTextField-root": {
          "&:hover": {
            border: "1px solid #F0F0F0 !important",
          },
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error": {
          border: "1px solid #f44336",
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: "#f44336",
          },
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#F0F0F0",
          },
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#F0F0F0",
          borderRadius: "2px",
        },
        "& .MuiFormHelperText-contained": {
          margin: "0 !important",
          display: "flex"
        },
        "& .MuiInputBase-input": {
          color: "#375280",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
        },
      },
      "& .btn-container": {
        display: "flex",
        gap: 16,
        height: "54px",
        width: "100%",
        marginTop: "52px",
        "& .back-btn": {
          textTransform: "capitalize",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "50%",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .next-btn": {
          textTransform: "capitalize",
          color: "white",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "50%",
          height: "100%",
          borderRadius: "2px",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
      "& .finance-status":{
      "& .MuiFormHelperText-root.Mui-error":{
        display: "flex"
      }
    }
    },
  },
});

export const TimePickerContainer = styled(Box)({
  width: "100%",
  "& .MuiFormControl-root.MuiTextField-root": {
    width: "100%",
    height: "100%",
    margin: 0,
    borderRadius: 8,
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336",
      "& .MuiInputBase-input": {
        color: "#f44336",
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      height: 56,
      "& .MuiInputBase-input": {
        color: "#94A3B8",
        height: "100%",
        padding: "15px 16px",
        fontWeight: 400,
        fontSize: 18,
        fontFamily: "Lato, sans-serif",
      },
    },
    "& .MuiInput-underline:before": {
      border: "none !important",
    },
    "& .MuiInput-underline:after": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important",
      display: "flex"
    },
    "& .MuiFormHelperText-root.Mui-error":{
      display: "flex"
    }
  },
});

export const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});

export const Step3Title = styled(Typography)({
        paddingTop: 12,
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        fontFamily: "Lato, sans-serif",
        textOverflow: "ellipsis",
        fontStyle: "normal",
        fontSize: 18,
        fontWeight: 700,
        overflow: "hidden"
})


export const TextFieldComponent = styled(TextField)(({ errorCheck }:any) => ({
'& .MuiFormHelperText-root.Mui-error':{
  display:errorCheck!=="ltr"?"flex":"unset"
}
}))
// Customizable Area End



