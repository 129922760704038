import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { addressback, view1,locationn } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Footer from "../../../components/src/Footer";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import AccountActiveProfileController, {
  Props,
  configJSON,
  CountrySuccess
} from "./AccountActiveProfileController.web";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
import CustomizedSteppers from "../../../components/src/Stepper";
import CountrySelect from "../../../components/src/CountryDropDown.web";
import { GoogleMap, Marker } from "@react-google-maps/api";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ActiveAddress extends AccountActiveProfileController {
  // Customizable Area Start
  errorFildError = () => {
    return this.state.emailError ? "inputFeild2Error2" : "inputFeild2"
  }
  handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
  
          this.setState({
            currentLocation: currentLatLng,
            selectedLocation: currentLatLng, 
            mapVisible: true,
              latitude: currentLatLng.lat,
              longitude: currentLatLng.lng,
           
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    } 
  };

  handleUseCurrentLocation2 = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
  
          this.setState({
            currentLocation2: currentLatLng,
            selectedLocation2: currentLatLng, 
            mapVisible2: true, 
              latitude2: currentLatLng.lat,
              longitude2: currentLatLng.lng,
           
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    } 
  };
 
  
  renderGoogleMap() {
    return (
      this.state.mapsLoaded &&
      this.state.mapVisible &&
      this.state.currentLocation && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation || { lat: -34.397, lng: 150.644 }}
        >
          {this.state.selectedLocation && (
            <Marker position={this.state.selectedLocation} />
          )}
        </GoogleMap>
      )
    );
  }

  renderGoogleMap2() {
    
    return (
      this.state.mapsLoaded2 &&
      this.state.mapVisible2 &&
      this.state.currentLocation2 && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation2 || { lat: -34.397, lng: 150.644 }}
        >
          {this.state.selectedLocation2 && (
            <Marker position={this.state.selectedLocation2} />
          )}
        </GoogleMap>
      )
    );
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleNextClick2 = this.handleNextClick2.bind(this);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { errors } = this.state;

    return (
      // Required for all blocks
      <>
        <SellerHeaderActive navigation={this.props.navigation} />
        <MainGrid data-test-id="mainGridContainer" container id="topId" dir={i18n.dir()}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              <img src={addressback} alt="" className="sideImage" />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>

            <Box className={"formBox"}>

              <form data-test-id="formSubmitted" style={{ width: "80%" }}>
                <Box width={"100%"}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                    <img src={view1} alt="" className="view" data-test-id="getNavigationProfile" onClick={() => this.getNavigationProfile()} />
                    <Typography className={"welcomeText"}>{this.transAccountActivation("Account Activation")}</Typography>
                  </Box>
                  <Box style={{ color: "#375280", fontFamily: "Lato" }}>
                  <CustomizedSteppers
                  dir={i18n.dir()}
                  activeStep={1}  
                  steps={[`${this.transAccountActivation('Create Profile')}`, `${this.transAccountActivation('Address')}`, `${this.transAccountActivation('Documents')}`]} 
                  stepCount={3} 
                />

                  </Box>
                  <Box style={{ fontSize: '20px', marginBottom: "30px" }}>
                    <Typography className={"welcomeText"}>{this.transAccountActivation("Owner’s Address")}</Typography>
                  </Box>
                  <Typography className={"inputHeading"}>{this.transAccountActivation("Full Address")}*</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={this.transAccountActivation("Enter Full Address")}
                    name="owner_full_address"
                    value={this.state.formData.owner_full_address}
                    onChange={this.handleInputChange}
                    onClick={()=>{
                      !this.state.mapVisible &&this.handleUseCurrentLocation()
                    }}
                    className={this.errorFildError()}
                    data-test-id="owner_full_address"
                    inputProps={{maxLength: 420, id: "autocomplete1"}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {<img src={locationn} alt="location" />}
                        </InputAdornment>
                      ),
                    }}

                  />
<div style={{ position: "relative" }}>
      {this.renderGoogleMap()}
    </div>
                  {errors.owner_full_address && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.owner_full_address}</span>}

                  <Typography className={"inputHeading"}>{this.transAccountActivation("Zip Code")}</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-test-id="owner_zip_code"
                    placeholder={this.transAccountActivation("Enter Zip Code")}
                    inputProps={{maxLength: 6}}
                    name="owner_zip_code"
                    value={this.state.formData.owner_zip_code}
                    onChange={(e:any)=>this.handleInputChange(e)}
                    className={this.errorFildError()}

                  />
 {errors.owner_zip_code && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.owner_zip_code}</span>}
                  <MainGridContainer container>
                    <Grid item style={{ width: "100%" }}>
                      <Box>
                        <Typography
                          data-test-id="countryId"
                          className={"inputHeading"}
                          id="phoneNumber"
                        >
                          {this.transAccountActivation("Phone Number")}
                          <span style={{ color: '#375280' }}>*</span>
                        </Typography>
                        <Box
                          className={"phoneInput"}
                          style={{ width: "100%" }}
                        >
                          <CustomDropDownBox>
                          <CountrySelect
                                            id="phoneCode"
                                            name="phoneCode"
                                            value={this.state.selectedCountry?.numeric_code || "+965"}
                                            placeholder="Select Phone Code"
                                            onChange={this.handleCountryChange}
                                            countryCode={this.state.countrySuccess}
                                            isBorder
                                        />
                          </CustomDropDownBox>
                          <TextField
                            data-test-id="owner_contact_number"
                            variant="outlined"
                            className="inputFeild2"
                            style={{ width: "100%" }}
                            placeholder={this.transAccountActivation("Enter Contact Number")}
                            inputProps={{ maxLength: 12 }}
                            name="owner_contact_number"
                            value={this.state.formData.owner_contact_number}
                            onChange={this.handleInputChange}
                            InputProps={{
                              style: {
                                gap: "4px",
                                border: "0px",
                                borderRadius: "8px",
                                width: "100%"
                              }
                            }}
                          />


                        </Box>
                          {errors.owner_contact_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.owner_contact_number}</span>}
                      </Box>
                    </Grid>
                  </MainGridContainer>

                  <Box style={{ fontSize: '20px', marginBottom: "30px", marginTop: '30px' }}>
                    <Typography className={"welcomeText"}>{this.transAccountActivation("Company’s Address")}</Typography>
                  </Box>

                  <Typography className={"inputHeading"}>{this.transAccountActivation("Full Address")}*</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={this.transAccountActivation("Enter Full Address")}
                    name="company_full_address"
                    value={this.state.formData.company_full_address}
                    onChange={this.handleInputChange}
                    onClick={()=>{
                      !this.state.mapVisible2&&this.handleUseCurrentLocation2()
                    }}
                    className={this.errorFildError()}
                    data-test-id="company_full_address"
                    inputProps={{maxLength: 420, id: "autocomplete2"}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {<img src={locationn} alt="location" />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ position: "relative" }}>
      {this.renderGoogleMap2()}
    </div>
                  {errors.company_full_address && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.company_full_address}</span>}


                  <Typography className={"inputHeading"}>{this.transAccountActivation("Zip Code")}</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-test-id="company_zip_code"
                    placeholder={this.transAccountActivation("Enter Zip Code")}
                    name="company_zip_code"
                    value={this.state.formData.company_zip_code}
                    onChange={(e:any)=>this.handleInputChange(e)}
                    className= "inputFeild2"
                    inputProps={{maxLength: 6}}

                  />
                  {errors.company_zip_code && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.company_zip_code}</span>}

                  <MainGridContainer container>
                    <Grid item style={{ width: "100%" }}>
                      <Box>
                        <Typography
                          data-test-id="countryId"
                          className={"inputHeading"}
                          id="companyPhoneNumber"
                        >
                          {this.transAccountActivation("Phone Number")}
                          <span style={{ color: '#375280' }}>*</span>
                        </Typography>
                        <Box
                          className={"phoneInput"}
                          style={{ width: "100%" }}
                        >
                          <CustomDropDownBox>
                          <CountrySelect
                                            id="phoneCode1"
                                            name="phoneCode1"
                                            value={this.state.selectedCountry1?.numeric_code || "+965"}
                                            placeholder="Select Phone Code"
                                            onChange={this.handleCountry1Change}
                                            countryCode={this.state.countrySuccess}
                                            isBorder
                                        />
                          </CustomDropDownBox>
                          <TextField
                            data-test-id="company_contact_number"
                            variant="outlined"
                            className="inputFeild2"
                            style={{ width: "100%" }}
                            placeholder={this.transAccountActivation("Enter Contact Number")}
                            inputProps={{ maxLength: 12 }}
                            name="company_contact_number"
                            value={this.state.formData.company_contact_number}
                            onChange={this.handleInputChange}
                            InputProps={{
                              style: {
                                gap: "4px",
                                border: "0px",
                                borderRadius: "8px",
                                width: "100%"
                              }
                            }}
                          />


                        </Box>
                          {errors.company_contact_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.company_contact_number}</span>}
                      </Box>
                    </Grid>
                  </MainGridContainer>

                  <Box style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "20px" }}>
                    <Button
                      variant="contained"
                      className={"backButton"}
                      disableElevation
                      type="submit"
                      data-test-id="getNavigationProfile1"
                      onClick={() => this.getNavigationProfile()}
                    >
                      {this.transAccountActivation("Back")}
                    </Button>

                    <Button
                      variant="contained"
                      className={"nextButton"}

                      data-test-id="getNavigationDocument"
                      onClick={() => this.handleSubmit1()}
                    >
                      {this.transAccountActivation("Next")}
                    </Button>
                  </Box>
                  <Snackbar
                    data-test-id="alertTestingId"
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  >
                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                  </Snackbar>
                </Box>

              </form>

            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  "& .sideImageBox": {
    height: "100%",
    width: "100%",
  },

  "& .imageGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .sideImage": {
    height: "100%",
    width: "100%",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    alignSelf: "flex-start",
    lineHeight: "26px",
    fontWeight: 200,
    margin: "10px 0px 0px 0px",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Avenir, sans-serif",
  },
  "& .inputFeild": {
    borderRadius: "0px",
    border: "none",
    gap: "4px",
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%",
  },
  "& .formBox": {
    marginTop: "30px",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    alignSelf: "flex-start",
    lineHeight: "26px",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    fontFamily: 'Lato , sans-serif',

  },

  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "18px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    textOverflow: "ellipsis",
    margin: "20px 10px 10px 0px",
  },
  "& .forgotPassword": {
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    cursor: "pointer",
    textOverflow: "ellipsis",
    margin: "10px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
  },
  "& .backButton": {
    gap: "8px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    borderRadius: "0px !important",
    border: "1px solid #CCBEB1",
    backgroundColor: "white",
    width: "50% !important",
    textTransform: "capitalize",
    height: "56px !important",
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 600,
    }
  },
  "& .nextButton": {
    width: "50% !important",
    textTransform: "capitalize",
    color: "white !important",
    borderRadius: "0px !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    height: "56px !important",
    gap: "8px",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .terms": {
    width: "520px !important",
    margin: "10px 10px 10px 10px !important",
    fontWeight: 400,
    color: "#979797 !important",
    lineHeight: "24px !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontFamily: "Lato ,sans-serif",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
  
  },
  "& .Register": {
    justifyContent: "center",
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  "& .socialLogin": {
    justifyContent: "center",
    alignItems: "center",
    gap: "35px",
    display: "flex",
    width: "100%",
    padding: "20px",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",
    lineHeight: "24px !important",
    fontWeight: 700,
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
  },
  "& .bottomText": {
    lineHeight: "24px !important",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
    color: "#375280 !important",
  },
  "& .continueText": {
    fontWeight: 400,
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    fontSize: "16px !important",
    display: "flex",
    marginTop: "15px",
    fontFamily: "Lato, sans-serif",
    color: "#375280 !important",
    flexDirection: "row",
    width: "100%",
    "&:before, &:after": {
      content: "''",
      borderBottom: "1px solid",
      flex: "1 1",
      margin: "auto",
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .backButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontWeight: 600,
        fontSize: "16px"
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      lineHeight: "24px !important",
      fontWeight: 400,
      fontStyle: "normal !important",
      fontSize: "16px !important",
      display: "flex",
      fontFamily: "Lato, sans-serif",
      color: "#375280 !important",
      width: "100%",
      marginTop: "15px",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto",
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
});

const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",

  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "26px",
    fontFamily: "Avenir,sans-serif",
    alignSelf: "flex-start",
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },

  "& .inputHeading": {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "18px",
    marginTop: "20px",
    fontStyle: "normal",
    marginBottom: "10px",
    color: "#375280",
    fontFamily: "Lato, sans-serif",
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    width: "100%px",
    height: "56px",
    gap: "15px",
    display: "flex",
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    fontFamily: "Lato, sans-serif",
    cursor: "pointer",
    margin: "10px",
    textOverflow: "ellipsis",
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },

  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },

      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});

const OptionsValueList = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
const CustomDropDownBox = styled(Box)({
  width: "180px",
  cursor: "pointer",
  position: "relative",
  height: "100%)"
});
const DropDownHeaderMenu = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  gap: "15px",
  background: "#F0F0F0",
  fontSize: "18px",
  color: "#375280",
  height: "36px",
  padding: "10px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
  backgroundColor: "#fff",
  color: "#375280",
  overflowX: "hidden",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  height: "60vh",
  zIndex: 1,
  position: "absolute",
  border: "1px solid #ccc",
  left: 0,
  width: "100%",
});
// Customizable Area End
