export type LocationDetails = {
 city: string;
 postalCode: string;
 area: string;
 formattedAddress: string;
 houseNumber: string;
 blockNumber: string
};

export async function getLocationDetails(lat: number, lng: number): Promise<LocationDetails> {
 const geocoder = new window.google.maps.Geocoder();
 const location = { lat, lng };

 const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
   geocoder.geocode({ location }, (results, status) => {
     if (status === "OK" && results && Array.isArray(results) && results.length > 0) {
       resolve(results);
     } else {
       reject(`Geocoding failed: ${status}`);
     }
   });
 }).catch(() => null);

 if (!results || results.length === 0) {
   return { city: "", postalCode: "", area: "", formattedAddress: "", houseNumber: "", blockNumber: "" };
 }

 const addressComponents = results[0]?.address_components;
 const formattedAddress = results[0]?.formatted_address;

 let postalCode = "";
 let city = "";
 let area = "";
 let houseNumber = "";
 let blockNumber = ""

 addressComponents.forEach((component) => {
   if (component.types.includes("postal_code")) {
     postalCode = component.long_name;
   }
   if (component.types.includes("locality")) {
     city = component.long_name;
   }
   if (
     component.types.includes("sublocality") ||
     component.types.includes("sublocality_level_1")
   ) {
     area = component.long_name;
   }
   if (component.types.includes("street_number")) {
    houseNumber = component.long_name;
   }
   if (component.types.includes("street_address") || component.types.includes("premise")) {
     blockNumber = component.long_name;
   }
 });

 return { city, postalCode, area, formattedAddress, houseNumber, blockNumber };
}
