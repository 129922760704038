import { Route } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import storage from "../../../framework/src/StorageProvider.web";
import EmailAccountLoginBlock from "../../../blocks/email-account-login/src/EmailAccountLoginBlock.web";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, []);

  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div ref={containerRef} style={{ }}>
      {React.cloneElement(element, {
        navigation: { navigate, getParam, goBack, history },
        closeModal
      })}
    </div>
  );
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const publicRoute = ['/','/Login','/Signup', '/NewPassword','/OTPVarification','/LandingPage','/ResetPassword','/ResetOTPPassword','/MobileAccountLoginBlock','/ForgotPassword','/ForgotPasswordOTP','/policies','/Faqs','/CategoriessubcategoriesWebPage','/AboutFash','/AccountActiveProfile','/DocumentAcitve','/ActiveAddress','/Productdescription3/:navigationBarTitleText','/ProductdetailsWeb', '/TrendingList', '/BuyerStore', '/ProductSearch', '/StoreProduct/:id', "/UserAccountActivation","/Contactus"]
const isPublicRoute = (path, publicRoutes) => {
  return publicRoutes.some(route => {
    const regex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}$`, 'i');
    return regex.test(path);
  });
};

const AuthRoute = ({ Component, props, routeMap, publicRoute }) => {
  const publicPath = props.location.pathname;
  const isLoggedIn = Boolean(localStorage.getItem('auth-token'));

  const outerPage  = ["/Login", "/", "/ForgotPassword", "/ResetOTPPassword", "/ResetPassword", "/Signup", "/OTPVarification", "/MobileAccountLoginBlock"];
  const role = JSON.parse(localStorage.getItem("userRole"));

  if(isLoggedIn && isPublicRoute(publicPath, outerPage)){
    if (props.history.length > 2 && (props.history.state === null || props.history.state === undefined)) {
      props.history.goBack();
     return;
    } 
      switch (role?.userType) {
        case "seller":
          props.history.push({
            pathname: '/SellerDashboard',
            state: { myData: 'historyWipe' },
          });
          break;
        case "stylist":
          props.history.push({
            pathname: '/StylishDashboard',
            state: { myData: 'historyWipe' },
          });
          break;
        case "buyer":
          props.history.push({
            pathname: '/LandingPage',
            state: { myData: 'historyWipe' },
          });
          break;
        default:
          props.history.push({
            pathname: '/',
            state: { myData: 'historyWipe' },
          });
          break;
      } 
return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
  }
  else if(isPublicRoute(publicPath, publicRoute) && role?.userType !== 'stylist' && !isLoggedIn){
    return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
  }
  else if(isPublicRoute(publicPath, publicRoute) && !isLoggedIn){
    return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
  }else if(isLoggedIn){
    const buyerStylish = JSON.parse(localStorage.getItem("exploreBuyer"));
    const buyerRoute = ['/profile', '/LandingPage', "/CategoriessubcategoriesWebPage", "/BuyerStore", "/HiredStylist", "/SourceProduct", "/StylistQuote/:id", "/HiredStylistProfile/:navigationBarTitleText", "/BuyerStoreProduct", "/StylingClientWishlist",
      "/HireStylistFormWeb/:navigationBarTitleText", "/ProductSearch", "/Notifications", "/ProductdetailsWeb", "/OrderManagementPage",'/BuyerReturnOrderDetailsWebPage/:navigationBarTitleText',"/ReturnStatus/:navigationBarTitleText", "/OrderDetails/:navigationBarTitleText", "/Wishlist", "/Productdescription3/:navigationBarTitleText", "/PaymentHistoryWeb","/PaymentHistoryDetails/:order_id", "/Loyaltysystem", "/Savedcards", "/FavouriteStylist", "/AddAddress", "/EditAddress", "/SuccessAddress", "/Setting", "/TrendingList", "/Faqs", "/Policies", "/AboutFash", "/StoreProduct/:id", "/Shippingaddressvalidation2", "/AddShippingaddressvalidation2", "/EditShippingaddressvalidation2", "/PaymentDetails","/TrackOrder/:navigationBarTitleText", "/MobileZylerGo"];
    const sellerRoute = ['/SellerStore', "/SellerDashboard", "/OrderManagement", "/Notifications", "/SellerProfile", "/AdminRequest", "/BankDetails", "/Faqs", "/Policies", "/Catalogue", "/ProductDetail", "/ProductAnalytics/:navigationBarTitleText","/CreateSellerStore", "/MyStore", "/AnalyticsReport", "/EarningsAnalyticsWebPage", "/GetHelp", "/SellerStoreProfile", "/EditSellerStore", "/SellerCatalogues", "/InventoryManagement", "/DeleteProducts", "/SellerOffersDiscounts", "/SellerOffersDiscountsCreateWebPage", "/SellerOffersStoreFormWebPage", "/SellerSelectProductsWebPage", "/SellersAllOrderDetailsWeb/:navigationBarTitleText", "/SellersInProcessOrderDetails/:id", "/SellerReadyForCollectionDetail/:id","/SellerDeliveredDetail/:id", "/SellersOrderDetailsWebPage/:navigationBarTitleText", "/AccountActiveProfile", "/ActiveAddress", "/DocumentAcitve", "/ReturnDetail/:navigationBarTitleText","/OutForDeliveryDetail/:navigationBarTitleText","/SellersRejectedOrderDetails/:navigationBarTitleText","/Setting", "/Notificationsettings"];
    const stylistRoute = ['/NewOrderRequest/:id','/newPaymentRequest/:id','/StylishDashboard', "/StylistAccountActiveProfile", "/StylistContactPayment", "/StylistPortfolioAcitve", "/StylistUploadDocumentsweb", "/StylingRequests", "/OrderManagement", "/StylishClients", "/StylishMyClients", "/StylingRequests", "/PhotoLibrary", "/InventoryManagement", "/DeleteProducts", "/AnalyticsReport", "/EarningsAnalyticsWebPage", "/GetHelp", "/AdminRequest", "/BankDetails", "/Faqs", "/Policies", "/StylishCatalogue", "/StylishProductSourcing", "/StylishMyOffer", "/StylishProductSourcingRequest", "/Catalogue", "/ServicesList", "/StylishProfile", "/StylingClientWishlist","/ClientChat", "/ClientChatID/:chat_id", "/ClientChatReceiver/:receiver_id", "/CategoriessubcategoriesWebPage", "/ReturnDetail/:navigationBarTitleText","/OutForDeliveryDetail/:navigationBarTitleText","/SellersRejectedOrderDetails/:navigationBarTitleText", "/SellersAllOrderDetailsWeb/:navigationBarTitleText", "/SellersInProcessOrderDetails/:id", "/SellerReadyForCollectionDetail/:id","/SellerDeliveredDetail/:id", "/Notifications","/Setting", "/Notificationsettings"];
    if(role?.userType === 'buyer' && isPublicRoute(publicPath, buyerRoute)){
      return <Wrapper element={<Component />} {...props} routeMap={routeMap} />
    }else if(role?.userType === 'seller' && isPublicRoute(publicPath, sellerRoute)){
      return <Wrapper element={<Component />} {...props} routeMap={routeMap} />
    }else if(role?.userType === 'stylist'){
      if(buyerStylish && (isPublicRoute(publicPath, buyerRoute) || isPublicRoute(publicPath, stylistRoute))){
        return <Wrapper element={<Component />} {...props} routeMap={routeMap} />
      }else if(isPublicRoute(publicPath, stylistRoute)){
        return <Wrapper element={<Component />} {...props} routeMap={routeMap} />
      }
    }
    return (
      <>
      {props.history.goBack()}
      </>
  ) 
  }else{
    props.history.push('/Login');
    storage.set("login_page", "true")
        setTimeout(() => {
          storage.remove("login_page")
  
        }, 1500);
    return (
      <Wrapper element={<EmailAccountLoginBlock />} {...props} routeMap={routeMap} />
    );
  }
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <AuthRoute
              Component={Component}
              props={props}
              routeMap={routeMap}
              publicRoute={publicRoute}
            />
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;