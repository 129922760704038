import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { location } from "../../addressmanagement/src/assets"
import CheckoutSteps from "../../productdescription3/src/CheckoutSteps.web";
import { createTheme ,styled, withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {Noimageavailable} from "../../productdescription3/src/assets"
import { GoogleMap, Marker } from "@react-google-maps/api";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import i18n from "../../../components/src/i18next/i18n";
import { getLocationDetails } from "../../../components/src/Googlelogin/googleMap.web";
// Customizable Area End

import Shippingaddressvalidation2Controller, {
  Props,
  CountrySuccess
} from "./Shippingaddressvalidation2Controller";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import CountrySelect from "../../../components/src/CountryDropDown.web";
import { CustomTypography } from "../../addressmanagement/src/EditAddress.web";

export default class EditShippingaddressvalidation2 extends Shippingaddressvalidation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleToggle = this.handleToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderLocationMapButton() {
    return (
      <div
        className="address_child"
        style={{
          gap: "8px",
          marginBottom: "22px",
          alignItems: "center",
          borderRadius: "2px",
          display: "flex",
          padding: "19px 35px",
          backgroundColor: "#CCBEB1",
          flexDirection: i18n.dir() === "ltr" ? "row": "row-reverse",
          marginLeft: "10px",
          width: 184
        }}
        onClick={this.handleUseCurrentLocation}
      >
        <img src={location} alt="location icon" />
        <CustomTypography>{this.transShippingAddress("Use my current location")}</CustomTypography>
      </div>
    );
  }
  renderSearchInput() {
    return (
      this.state.mapsLoaded2 && (
        <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
          <input
            id="autocomplete2"
            type="text"
            placeholder="Search for a place"
            style={{
              width: "254px",
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              display: this.state.mapVisible2 ? "block" : "none",
            }}
          />
        </div>
      )
    );
  }
  renderGoogleMap() {
    return (
      this.state.mapsLoaded2 &&
      this.state.mapVisible2 &&
      this.state.currentLocation2 && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation2} 
        >
          {this.state.selectedLocation2 && (
            <Marker position={this.state.selectedLocation2} /> 
          )}
        </GoogleMap>
      )
    );
  }
  handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
       async (position) => {
         const currentLatLng = {
           lat: position.coords.latitude,
           lng: position.coords.longitude,
          };
          this.setState({
            currentLocation2: currentLatLng,
            selectedLocation2: currentLatLng, 
            mapVisible2: true })
          const { blockNumber, city, postalCode, area, houseNumber, formattedAddress} = await getLocationDetails(currentLatLng.lat, currentLatLng.lng)
  
          this.setState({
            currentLocation2: currentLatLng,
            selectedLocation2: currentLatLng, 
            mapVisible2: true, 
            showaddressData: {
              ...this.state.showaddressData,
              house_or_building_number: houseNumber,
              latitude: currentLatLng.lat,
              zipcode: postalCode,
              area,
              longitude: currentLatLng.lng,
              block: blockNumber,
              city,
              street: formattedAddress,
            },
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    } 
  };
  renderOrderSummaryDetail() {
    const { orderSummary } = this.state;
    if (!orderSummary) return null;

    const { order_items, sub_total, total } = orderSummary.data.attributes;

    return (
      <div>
        {order_items.map((item) => (
          <div>
          <div key={item.id} style={{ ...webStyle.shippingOrder, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailable : item.attributes.catalogue_variant_front_image} 
            style={{ width: '63px', height: '63px', borderRadius: '4px' }} />
            <div style={{ ...webStyle.orderDetail, flex: '1', marginLeft: '10px' }}>
              <p>{item.attributes.catalogue_name} <span style={{ color: '#94A3B8' }}>x {item.attributes.quantity}</span></p>
              <p style={{ marginTop: '-18px' }}>{this.transShippingCart("Color")} : <span style={{ color: '#94A3B8' }}>{item.attributes.catalogue_variant_color}</span></p>
            </div>
            <p style={{ fontSize: '14px', marginLeft: '10px', color: '#94A3B8' }}>{this.state.currencySign} {item.attributes.total_price}</p>
          </div>
          <hr style={{ color: 'rgb(242, 240, 240)' }} />
          </div>
        ))}
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1', margin: i18n.dir()==='ltr'?'0px 0px 0px 10px': '0px 10px 0px 0px', fontSize: '18px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>{this.transShippingCart("Subtotal")} <span style={{ color: '#94A3B8' }}>({order_items.length} {this.transShippingCart("items")})</span></p>
              <p style={{ fontSize: '18px' }}>{this.state.currencySign} {parseFloat(sub_total).toFixed(2)}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
              <p style={{ marginTop: '-5px' }}>{this.transShippingCart("Savings")}</p>
              <p style={{ marginTop: '-5px' }}>-{this.state.currencySign} 0.00</p>
            </div>
          </div>
        </div>
        <hr style={{ color: 'rgb(242, 240, 240)' }} />
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1', marginLeft: '10px', fontSize: '18px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
              <p>{this.transShippingCart("Total")}</p>
              <p>{this.state.currencySign} {parseFloat(total).toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div style={{ ...webStyle.placeOrderbutton, ...webStyle.saveButton }}
        data-test-id="placeOrderNavigation"
        onClick={this.placeOrderNavigation}>{this.transShippingCart("Place Order")}</div>
      </div>
    );
  }

  // Customizable Area End

  render() {
    const { errors } = this.state;

    const GreenSwitch = withStyles({
      switchBase: {
        color: "#fff",
        "&$checked": {
          transform: "translateX(22px)",
          color: "#fff",
          "& + $track": {
            height: 17,
            border: "none",
            opacity: 1,
            backgroundColor: "#CCBEB1",
          }
        },
        "&$focusVisible $thumb": {
          border: "6px solid #fff",
          color: "#4cd964",
        }
      },
      thumb: {
        width: 19,
        height: 21
      },
      track: {
        borderRadius: 16 / 2,
        border: "1px solid #E5E5EA",
        opacity: 1,
        backgroundColor: "#E5E5EA",
        transition: "background-color 0.3s"
      },
      checked: {},
      focusVisible: {}
    })(Switch);
    return (
      // Customizable Area Start
      <>
        {this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation} />: <Header navigation={this.props.navigation} />}
        <Box style={{color: "#375280", backgroundColor: "rgb(248, 248, 248)",  fontFamily: "Lato" }}>
          <CheckoutSteps activeBread="address" transStepLang={this.transShippingCart}/>

        </Box>
        <div style={{ display: "flex", padding: "40px",backgroundColor: "#F8F8F8" }}>
          <Grid
            style={{ marginBottom: "58px",  }}
            container
            dir={i18n.dir()}
          >
            <Grid item xs={12} md={8} style={{ paddingInline: 20 }} >
            <div style={{ backgroundColor: "#F8F8F8" }}>
              <div>
                <div style={webStyle.addAddressContainer as React.CSSProperties}>

                  <div
                    className="address_child"
                    style={{
                      fontFamily: 'Lato , sans-serif',
                      marginLeft: "10px",
                      marginBottom: "22px",
                      color: "#375280",
                      fontSize: "20px",
                    }}
                  >
                    {this.transShippingAddress("Edit Address")}
                  </div>
               
                  <div style={{ position: "relative" }}>
      {this.renderLocationMapButton()}
      {this.renderSearchInput()}
      {this.renderGoogleMap()}
    </div>

                  <Grid
                    container
                    spacing={2}
                    style={{ width: "100%", margin: 0 }}
                  >
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontFamily: "Lato , sans-serif",
                          color: "#375280",
                          lineHeight: "24px",
                          fontWeight: 700,
                          fontSize: "16px",

                        }}

                      >
                        {this.transShippingAddress("Full Name")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        fullWidth
                        name="name"
                        variant="outlined"
                        value={this.state.showaddressData.name}
                        onChange={this.handleInputChange}
                        data-test-id="memberId"

                      />
                      {errors.name && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.name}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MainGridContainer container>
                        <Grid item style={{ width: "100%" }}>
                          <Box>
                            <Typography
                              data-test-id="countryId"
                              className={"inputHeading"}
                            >
                              {this.transShippingAddress("Phone Number")}
                              <span style={{ color: '#375280' }}>*</span>
                            </Typography>
                            <Box
                              className={"phoneInput"}
                              style={{ width: "100%" }}
                            >
                              <CustomDropDownBox>
                              <CountrySelect
                                id="phoneCode"
                                name="phoneCode"
                                value={this.state.selectedCountry?.numeric_code || "+965"}
                                placeholder="Select Phone Code"
                                onChange={this.handleCountryeditSelect}
                                countryCode={this.state.countrySuccess}
                                isBorder
                              />
                              </CustomDropDownBox>
                              <TextField
                                name="phone_number"
                                value={this.state.showaddressData.phone_number}
                                onChange={this.handleInputChange}
                                variant="outlined"
                                placeholder={this.transShippingAddress("Enter Contact Number")}
                                inputProps={{ maxLength: 12 }}
                                className="inputFeild2"
                                style={{ width: "100%" }}
                                data-test-id="phoneNumberTextFiled"
                                InputProps={{
                                  style: {
                                    gap: "4px",
                                    border: "0px",
                                    borderRadius: "8px",
                                    width: "100%",
                                  }
                                }}
                              />
                            </Box>
                            {errors.phone_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.phone_number}</span>}

                          </Box>
                        </Grid>
                      </MainGridContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontFamily: "Lato , sans-serif",
                          color: "#375280",
                          lineHeight: "24px",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                      >
                        {this.transShippingAddress("Street Name")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        name="street"
                        variant="outlined"
                        value={this.state.showaddressData.street}
                        onChange={this.handleInputChange}
                        data-test-id="memberId"
                        fullWidth

                      />
                      {errors.street && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.street}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.transShippingAddress("House / Building Number")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="house_or_building_number"
                        value={this.state.showaddressData.house_or_building_number}
                        onChange={this.handleInputChange}

                      />
                      {errors.house_or_building_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.house_or_building_number}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.transShippingAddress("Block")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="block"
                        value={this.state.showaddressData.block}
                        onChange={this.handleInputChange}
                        placeholder={this.transShippingAddress("Enter Block")}
                      />
                      {errors.block && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.block}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.transShippingAddress("Area")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="area"
                        value={this.state.showaddressData.area}
                        onChange={this.handleInputChange}

                      />
                      {errors.area && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.area}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.transShippingAddress("City")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="city"
                        value={this.state.showaddressData.city}
                        onChange={this.handleInputChange}

                      />
                      {errors.city && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.city}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.transShippingAddress("Zip Code")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="zipcode"
                        value={this.state.showaddressData.zipcode}
                        onChange={this.handleInputChange}

                      />
                      {errors.zipcode && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.zipcode}</span>}

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          color: "#375280",
                          fontFamily: "Lato , sans-serif",
                        }}
                      >
                        {this.transShippingAddress("Address Name")}
                        <span style={{ color: '#375280' }}>*</span>
                      </p>
                      <InputField
                        data-test-id="memberId"
                        fullWidth
                        variant="outlined"
                        name="address_name"
                        value={this.state.showaddressData.address_name}
                        onChange={this.handleInputChange}

                      />
                      {errors.address_name && <span style={{ color: '#f44336', fontSize: "16px", fontFamily: "Lato , sans-serif", fontWeight: 100 }}>{errors.address_name}</span>}

                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#375280",
                      fontSize: "16px",
                      fontWeight: 500
                    }}
                  >
                    <GreenSwitch
                      checked={this.state.showaddressData.is_default}
                      onChange={this.handleToggle}
                      data-test-id="handleToggle"
                      inputProps={{ "aria-label": "toggle switch" }}
                    />
                    {/* <p>Toggle is {this.state.isChecked ? 'ON' : 'OFF'}</p> */}
                    <p>{this.transShippingAddress("Make it default delivery address")}</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 22,
                      paddingInline: 10
                    }}
                  >
                    <div style={webStyle.button12} onClick={this.getNavigationAddAddress}>{this.transShippingAddress("Cancel")}</div>
                    <div
                      style={{ ...webStyle.button12, ...webStyle.saveButton }}
                      onClick={this.handleUpdateSubmit}
                      data-test-id="handleUpdateSubmit"
                    >
                      {this.transShippingAddress("Save")}
                    </div>

                  </div>
                </div>
              </div>
            </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: "58px" , backgroundColor: "#F8F8F8",}}>

              <div style={{ paddingInline: 20 , margin: 'auto',}}>
                <div>

                  <div style={webStyle.orderAddAddressContainer as React.CSSProperties} >
                    <div style={{
                      color: "#375280",
                      gap: '4px', 
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                      marginBottom: "14px", 
                           }}>
                      {this.transShippingCart("Order Summary")}
                    </div>
                    <hr style={{ color: 'rgb(242, 240, 240)' }} />
                    {this.renderOrderSummaryDetail()}

                  </div>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          data-test-id="alertTestId"
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  addAddressContainer: {
    backgroundColor: "#FFFFFF",
    lineHeight: '26px',
    marginBottom: "24px",
    padding: "16px 12px 16px 16px",
    boxShadow: "0px 2px 8px 0px #00000014",
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Lato , sans-serif',
  },
  orderDetail: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 600,
    fontSize: "14px",
  },
  shippingOrder: { 
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "14px", 
    display: "flex",
    color: "#375280",
    gap: '4px',
   },
  orderAddAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    marginBottom: "24px",
    fontWeight: 700,
    fontSize: '24px',
    boxShadow: "0px 2px 8px 0px #00000014",
    lineHeight: '28.8px',
    padding: "16px 12px 16px 16px",
    backgroundColor: "#FFFFFF",
  },
  dropdown: {
    top: '100%',
    width: '120px',
    left: 0,
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px 0px #00000014',
    borderRadius: '4px',
  },
  dropdownP: {
    lineHeight: "22px",
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    borderBottom: '3px solid #D5D5D5',
    paddingBottom: '10px',
  },
  dropdownP1: {
    lineHeight: "22px",
    paddingRight: '6px',
    paddingLeft: '6px',
    fontWeight: "600",
    color: "#DC2626",
    fontSize: "12px",
  },
  button12: {
    color: "#375280",
    fontWeight: 700,
    fontSize: "18px",
    border: "1px solid #CCBEB1",
    alignItems: "center",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "white",
    marginLeft: "10px",
    height: "64px",
    marginRight: '5px',
    width: "50%",
    padding: "10px 16px 10px 16px",
  },
  placeOrderbutton: {
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    color: "#375280",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginRight: '5px',
    backgroundColor: "white",
    height: "64px",
    padding: "10px 16px 10px 16px",
  },
  button123: {
    color: "#375280",
    fontWeight: 700,
    fontSize: "18px",
    border: "1px solid #CCBEB1",
    alignItems: "center",
    borderRadius: "2px",
    justifyContent: "center",
    cursor: "pointer",
    marginLeft: "10px",
    display: "flex",
    marginRight: '5px',
    backgroundColor: "white",
    height: "64px",
    padding: "10px 16px 10px 16px",
  },
  saveButton: {
    backgroundColor: "#CCBEB1",
    color: "white",
  },

};
const InputField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#375280 !important" 
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#FFFFFF",
    }
  },
  "& .MuiInputBase-root": {
    margin: "auto",
    height: "56px",
    borderRadius: "8px",
    fontSize: "16px",
    backgroundColor: "#F0F0F0",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-input": {
    color: "#1E293B",
    padding: "10px 16px 10px 16px",
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",

  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    lineHeight: "26px",
    fontSize: "24px",
    fontStyle: "normal",
    alignSelf: "flex-start",
    fontWeight: 700,
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },

  "& .inputHeading": {
    color: "#375280",
    marginBottom: "10px",
    fontStyle: "normal",
    marginTop: "20px",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    width: "100%px",
    height: "56px",
    gap: "15px",
    display: "flex",
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none",
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    }
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "2px",
        backgroundColor: "#F0F0F0",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});
const CustomDropDownBox = styled(Box)({
  position: "relative",
  cursor: "pointer",
  width: "180px",
});
const DropDownHeaderMenu = styled(Box)({
  color: "#375280",
  fontSize: "18px",
  background: "#F0F0F0",
  height: "36px",
  gap: "15px",
  borderRadius: "2px",
  border: "none",
  padding: "10px",
  alignItems: "center",
  display: "flex",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  alignItems: "center",
  display: "flex",
});
const DropDownOptionsList = styled(Box)({
  position: "absolute",
  zIndex: 1,
  border: "1px solid #ccc",
  height: "60vh",
  borderRadius: "0 0 4px 4px",
  left: 0,
  borderTop: "none",
  overflowX: "hidden",
  width: "100%",
  color: "#375280",
  backgroundColor: "#fff",
});
const OptionsValueList = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
