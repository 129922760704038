import { Attributes } from "../../../blocks/notifications/src/NotificationsController";
import { message } from "../../../blocks/ordermanagement/src/assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

const { baseURL } = require("../../../framework/src/config");


export const setupOneToOneWebSocket = (
    URL: any,
    token: any,
    chatId: any,
    handleMessage: any
  ) => {
    const webSocket = new WebSocket(`wss://${URL}/cable?token=${token}`);
  
    webSocket.onopen = () => {
      console.log("WebSocket connection established.");
      webSocket.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "ChatChannel",
            id: chatId,
          })
        })
      );
    };
  
    webSocket.onclose = () => console.log("WebSocket connection closed.");
    webSocket.onerror = (error) => console.error("WebSocket error:", error);
  
    webSocket.onmessage = (event) => {
      let result = JSON.parse(event.data);
  
      if (result.message?.data?.attributes) {
        result = result.message.data;
        handleMessage(result);
      }
    };
  
    return webSocket;
};


export const formatChatDate = (dateString:string) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };
  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  let timeString = date.toLocaleTimeString([], options);
  if (isToday) {
    return timeString;
  } else if (isYesterday) {
    return "Yesterday";
  } else {
    return date.toLocaleDateString;
  }
}

export const formatLiveChatTime = (dateString:string) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const date = new Date(dateString);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.floor((now.getTime() - date.getTime()) / oneDay);

  if (diffDays === 0) {
    return date.toLocaleTimeString(undefined, options);
  } else if (diffDays === 1) {
    return `Yesterday, ${date.toLocaleTimeString(undefined, options)}`;
  } else {
    return `${date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })}, ${date.toLocaleTimeString(undefined, options)}`;
  }
}

export const truncateText = (title: string, titleLength: number) => {
  if (title === undefined || title === null) return "";
  if (title.length > titleLength) {
    return `${title.slice(0, titleLength)}...`;
  } else {
    return title;
  }
};

export const getFileType = (file: File | string): string => {
  let extension: string | undefined;

  if (typeof file === 'string') {
    try {
      const url = new URL(file);
      extension = url.pathname.split('.').pop()?.toLowerCase();
    } catch (error) {
      console.error('Invalid URL:', error);
      return 'invalid';
    }
  } else if (file instanceof File) {
    extension = file.name.split('.').pop()?.toLowerCase();
  } else {
    console.error('Invalid file object');
    return 'invalid';
  }

  if (extension) {
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
      return 'image';
    } else if (['mp3', 'wav', 'ogg'].includes(extension)) {
      return 'audio';
    } else if (['mp4', 'webm', 'ogg', 'mov'].includes(extension)) {
      return 'video';
    } else if (['pdf'].includes(extension)) {
      return 'pdf';
    } else if (['txt', 'csv', 'doc', 'docx'].includes(extension)) {
      return 'text';
    } else if (['xls', 'xlsx'].includes(extension)) {
      return 'excel';
    } else if (['ppt', 'pptx'].includes(extension)) {
      return 'powerpoint';
    }
  }

  return 'other';
};

export const handleImgUrl = (url: string): string => {
  if(!url) return ""
  const host = baseURL ?? ""

  try {
    const parsedUrl = new URL(url);
    return parsedUrl.href;
  } catch (e) {
    return `${host}/${url.replace(/^\/+/, '')}`;
  }
};

export const handleChatUrl = (url: string): string => {
  if (!url) return baseURL;

  const isHiredStylistProfile = url.includes("HiredStylistProfile");
  const alreadyContainsChat = url.includes("chat");

  if (isHiredStylistProfile && !alreadyContainsChat) {
    return url.replace(/\/(\d+)$/, '/chat$1');
  }

  return url;
};


export const handleNotificationClick = async (item: Attributes, navigate: any) => {
  const { contents, data, action } = item
  const messageS = contents ? contents.replace(/<[^>]*>?/gm, '') : "";
   const notificationActions = [
    { action: 'order_confirm', route: 'OrderDetails', params: { navigationBarTitleText: data.order_id } },
    { action: 'order_placed', route: 'OrderDetails', params: { navigationBarTitleText: data.order_id } },
    { action: 'delivery_alert', route: 'OrderDetails', params: { navigationBarTitleText: data.order_id } },
    { action: 'order_delivered', route: 'TrackOrder', params: { navigationBarTitleText: data.order_id } },
    { action: 'order_dispatched', route: 'TrackOrder', params: { navigationBarTitleText: data.order_id } },
    { action: 'ship_and_track_info', route: 'TrackOrder', params: { navigationBarTitleText: data.order_id } },
    { action: 'payment_done', route: 'PaymentHistoryDetails', params: { order_id: data.order_id } },
    { action: 'sign_up', route: 'LandingPage', isSignUp: true },
    { action: 'periodic_recommendation_engine_emailer_to_buyers', route: 'LandingPage' },
    { action: 'stylist_terms_updates', route: 'TermsConditions' },
    { action: 'seller_terms_updates', route: 'TermsConditions' },
    { action: 'seller_cancel_order', route: 'SellersAllOrderDetailsWeb', params: { navigationBarTitleText: data.order_number } },
    { action: 'appointment_is_made_by_client', route: 'SellersAllOrderDetailsWeb', params: { navigationBarTitleText: data.order_number } },
    { action: 'shopping_cart_abandoned_during_session', route: 'ProductdetailsWeb' },
    { action: 'redirect_to_new_products_page_every_week', route: 'LandingPage' },
    { action: 'store_adds_discounts_to_their_products', route: 'Loyaltysystem' },
    { action: 'buyer_catalogue_price_drop', route: 'Productdescription3', params: { navigationBarTitleText: data.catalogue_id } },
    { action: 'buyer_back_in_stock', route: 'Productdescription3', params: { navigationBarTitleText: data.catalogue_id } },
    { action: 'seller_new_order', route: 'SellerDashboard' },
    { action: 'seller_payment_confirm', route: 'SellerDashboard' },
    { action: 'seller_low_stock', route: 'InventoryManagement', isSetStoreId: true},
    { action: 'seller_product_price', route: 'InventoryManagement', isSetStoreId: true},
    { action: 'seller_store_approved', route: 'SellerStoreProfile', isSetStoreId: true},
    { action: 'monthly_performance_report', route: 'SellerAnalyticsInsights' },
    { action: 'product_sourcing_request_by_stylist', route: 'StylishProductSourcing' },
    { action: 'stylist_monthly_orders_report', route: 'StylistAnalytics' },
    { message: "Security Alerts: Notifications regarding account security or privacy updates", route: 'Notificationsettings' },
    { message: "Account Security Alerts: Notifications for password changes, account updates, or security alerts.", route: 'Notificationsettings' },
  ];
  
  const notificationAction = notificationActions.find((item) => 
    item.action === action
  );
  const notificationMessage = notificationActions.find((item) => 
    item?.message === messageS
  );

  if (notificationAction) {
    const { route, params, isSignUp, isSetStoreId } = notificationAction;

    if (isSignUp) {
      const userType = await getStorageData("userRole", true);
      const userRoutes: Record<string, string> = {
        buyer: "LandingPage",
        stylist: "StylishDashboard",
        seller: "SellerDashboard",
      };
      navigate(userRoutes[userType?.role] || "LandingPage");
      return;
    } else {

      if (isSetStoreId && data.store_id) {
        await setStorageData("store-id", data.store_id);
      }
      console.log("090909", route)
      params ?  navigate(route, params) :  navigate(route)
    }
  } else if(notificationMessage) {
    const { route, params, message } = notificationMessage;
    if(message){
      params ?  navigate(route, params) :  navigate(route)
    }
  }



}

